/** @format */

import React from "react";
import style from "../assets/style/Community.module.css";
import rc from "../assets/rc-community.png";

function Community() {
  return (
    <div className={`${style.community_Container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div className="row m-0 align-center">
          <div className="col-12">
            <img src={rc} alt="rc" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
