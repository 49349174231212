/** @format */

import React from "react";
import nig_1 from "../assets/nig_1.svg";
import replacement2 from "../assets/Rectangle2.png";
import nig_2 from "../assets/nig_2.svg";
import nig_3 from "../assets/nig_3.svg";
import nig_4 from "../assets/nig_4.svg";
import nig_5 from "../assets/nig_5.svg";

export default function BeautifyThings() {
  return (
    <div style={{ background: "#100F1B", color: "#fff" }} className="py-5">
      <div className="px-sm-5 px-2 w-100 text-center pt-3">
        <h2>
          Beautiful things about
          <strong style={{ color: "#8179FF" }}>Nigeria</strong>
        </h2>
        <div
          style={{ background: "#3F3881" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 order-2 order-lg-1 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Food and Delicacies</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Nigerian cuisine is a feast for the senses, offering a diverse
                range of flavors and dishes that reflect the country's rich
                cultural mosaic. From the spicy Jollof rice, a West African
                favorite, to the hearty pounded yam and egusi soup, there's
                something to delight every palate. Don't miss out on trying
                Suya, a spicy meat skewer, or Moi Moi, a savory steamed bean
                pudding. Our food is not just a meal but an experience, often
                enjoyed with the accompaniment of lively music and vibrant
                conversations
              </p>
            </div>
            <div className="col-12 order-1 order-lg-2 mb-3 col-lg-4 text-center">
              <img src={replacement2} alt="replacement2" width="340px" />
            </div>
          </div>
        </div>
        <div
          style={{ background: "#2D5136" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 mb-3 col-lg-4 text-center">
              <img src={nig_1} alt="nig_1" width="340px" />
            </div>
            <div className="col-12 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Hospitality</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Nigerians are known for their warmth and friendliness. You will
                find the people welcoming, eager to share their culture, and
                always ready to help. Whether you're navigating the Federal
                capital Territory Abuja or the bustling streets of Lagos, Port
                Harcourt or exploring the serene landscapes of Calabar, you'll
                be met with smiles and hospitality. Our tradition of welcoming
                guests is deeply rooted in our culture, making every visitor
                feel like a part of the family.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ background: "#1A192D" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 order-2 order-lg-1 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Culture and Attractions</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Beyond your conference, Nigeria offers a plethora of attractions
                to explore. From  the historic Benin City, home to ancient
                bronze artworks to the stunning Olumo Rock in Abeokuta as well
                as the Zuma Rock in Niger State, near Abuja and of course the
                lush greenery of Obudu Mountain Resort, nature enthusiasts will
                find much to admire as well as the Lekki Conservation Centre in
                Lagos which is a great place to experience Nigeria’s diverse
                flora and fauna, featuring one of the longest canopy walkways in
                Africa. The lively markets, such as Balogun Market in Lagos,
                provide a sensory overload of colours, sounds, and smells,
                offering everything from traditional crafts to modern fashion.
                Do not miss the opportunity to experience the vibrant nightlife
                in Lagos or the serene beauty of the Niger Delta.
              </p>
            </div>
            <div className="col-12 order-1 order-lg-2 mb-3 col-lg-4 text-center">
              <img src={nig_2} alt="nig_2" width="340px" />
            </div>
          </div>
        </div>
        <div
          style={{ background: "#2D4651" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 mb-3 col-lg-4 text-center">
              <img src={nig_3} alt="nig_3" width="340px" />
            </div>
            <div className="col-12 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Nigeria is hot</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Hot and humid! You are going to the right place if you are
                looking for sunshine because all through the year, Nigeria is
                generally warm. The weather varies from place to place for
                instance, Jos is typically cool and Maiduguri is typically hot.
                Port Harcourt is in the South -South area of Nigeria and in the
                tropical rain forest. It never snows in Nigeria, and it is often
                warm enough for children to play in the rain! The high humidity
                also makes it feel much hotter than it actually is and many
                people have air-conditioners in their cars, houses and offices
                to avoid the sticky sweatiness that comes with the weather. In
                Harmattan (the cooler, drier season that runs from late November
                till February or early March), the temperature drops slightly
                (especially in the evenings) and the trade winds bring along
                dryness and dust with them. Always have a hat, sunscreen and
                sunglasses to protect yourself as much as possible.
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ background: "#36345D" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 order-2 order-lg-1 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Everyone speaks English</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Nigerian speak fluent English but everyone speaks Pidgin
                English. Pidgin is a local variant —a unique language on its own
                — that combines local dialects, slang and some English words. No
                surprise, since English is the official language, and most
                people speak it alongside one or two of 350+ local languages.
              </p>
            </div>
            <div className="col-12 order-1 order-lg-2 mb-3 col-lg-4 text-center">
              <img src={nig_4} alt="nig_4" width="340px" />
            </div>
          </div>
        </div>
        <div
          style={{ background: "#3E6A52" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 mb-3 col-lg-4 text-center">
              <img src={nig_5} alt="nig_5" width="340px" />
            </div>
            <div className="col-12 mb-3 col-lg-8 pl-5">
              <h5 className="mb-4">Cultural Festivals</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Nigeria is famous for its vibrant festivals, which provide a
                glimpse into the country’s rich traditions and lively spirit.
                The Eyo Festival in Lagos, the Durbar Festival in Kano, and the
                Calabar Carnival are just a few examples of the colourful and
                energetic celebrations that take place throughout the year. If
                your visit coincides with any of these festivals, it’s a chance
                to witness the exuberant displays of music, dance, and cultural
                performances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
