/** @format */

import React from "react";
import style from "../assets/style/Dignitaries.module.css";
import Slider from "react-slick";
import dignitariesImages from "../dignitariesImage";

function Dignitaries() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`${style.dignitaries_container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div className="row m-0">
          <div className="col-12 text-center mb-5">
            <h4 className="mb-3">Featuring</h4>
            <h2>Supreme Grand Lodge Dignitaries</h2>
          </div>
          <div className="col-12">
            <Slider {...settings}>
              {dignitariesImages &&
                dignitariesImages.map((img, i) => (
                  <div key={i} className={`${style.image_container} px-2 py-2`}>
                    <img
                      src={img.img}
                      alt={img.label}
                      width="100%"
                      style={{
                        border: "1px solid #ffffff",
                        borderRadius: "16px",
                      }}
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dignitaries;
