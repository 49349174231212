/** @format */
 const venueImages = [
  {
    img: require("./assets/venue/Obi Wali Conference Center -2.jpg"),
    label: "Image One",
  },
  {
    img: require("./assets/venue/Obi Wali Conference Center -5.jpg"),
    label: "Image Two",
  },
  {
    img: require("./assets/venue/Obi Wali Conference Center -6.jpeg"),
    label: "Image Three",
  },
  {
    img: require("./assets/venue/Obi Wali Conference Center -4.jpg"),
    label: "Image Four",
  },
  {
    img: require("./assets/venue/Obi Wali Conference Center -1.jpg"),
    label: "Image Five",
  },
  {
    img: require("./assets/venue/Obi Wali Conference Center -3.jpg"),
    label: "Image Six",
  },
];

export default venueImages;
