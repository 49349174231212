/** @format */
const nigeriaImages = [
  {
    img: require("./assets/officials1.png"),
    label: "Image One",
  },
  {
    img: require("./assets/officials2.png"),
    label: "Image 11",
  },
  {
    img: require("./assets/officials3.png"),
    label: "Image Two",
  },
  {
    img: require("./assets/officials4.png"),
    label: "Image Three",
  },
  {
    img: require("./assets/officials5.png"),
    label: "Image Four",
  },
];

export default nigeriaImages;
