/** @format */

import React from "react";
import banner from "../assets/niger_banner.svg";

function NigeriaBanner() {
  return (
    <div className="py-5" style={{ background: "#13121d", color: "#fff" }}>
      <div className="pt-5">
        <img src={banner} alt="banner" width="100%" />
      </div>
      <div className="px-sm-5 px-2 w-100 pt-5">
        <div className="row m-0" style={{ color: "#EDEDED" }}>
          <div className="col-12 col-lg-10">
            <h4 style={{ fontWeight: "400" }}>
              We are excited to welcome you to Nigeria and are confident that
              your visit will be both productive and enjoyable. Our country's
              blend of modernity and tradition provides a unique backdrop for
              any conference, and we hope you take the time to explore and enjoy
              the many facets of Nigerian life.
            </h4>
            <h4 style={{ fontWeight: "400" }}>
              Have a wonderful stay in Nigeria, and we look forward to making
              your visit memorable!
            </h4>
            <button type="button" className="app-btn-primary mt-3">
              Register for the event
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NigeriaBanner;
