/** @format */

import "./App.css";
import { Route, Routes } from "react-router-dom";
import WebsiteRoute from "./route/websiteRoute";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<WebsiteRoute />} />
    </Routes>
  );
}

export default App;
