/** @format */

import React from "react";
import style from "../assets/style/About.module.css";
import calender from "../assets/calalender.svg";
import map from "../assets/map.svg";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className={`${style.about_container} py-5`}>
      <div className="px-lg-5 w-100">
        <div className="row m-0 align-items-center justify-content-center">
          <div className="col-12 col-lg-7 order-2 order-lg-1 mb-4 text-justify">
            <h2 style={{ fontWeight: "900" }}>
              About <b style={{ color: "#8179FF" }}>the Convention</b>
            </h2>
            <p>
              This Rosicrucian Convention is organized by the English Grand
              Lodge for West Africa of the Ancient Mystical Order Rosae Crucis
              (AMORC), it promises to be a significant gathering set to take
              place in Port Harcourt, Rivers State, Nigeria 31st October to 3rd
              November 2024. The convention holds special importance as it will
              be attended by international dignitaries, reflecting a growing
              global interest in Africa, West Africa and particularly Nigeria.
            </p>
            <p>
              The convention is not just a gathering but a celebration of our
              collective spiritual heritage, a communion of like-minded souls,
              and an opportunity for members to come together, deepen their
              understanding, and express the ideals of the Rosicrucian Tradition
              in their lives.
            </p>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="bd-highlight">
                <img src={calender} alt="calender" className="mr-2" />
              </div>
              <div className="bd-highlight">
                <span>Thur 31st Oct. - Sun 3rd Nov. 2024</span>
              </div>
            </div>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="bd-highlight">
                <img src={map} alt="map" className="mr-2" />
              </div>
              <div className="bd-highlight">
                <span>
                  Obi Wali International Conference <br /> Centre, Port
                  Harcourt, Rivers state.
                </span>
              </div>
            </div>
            <Link to="https://register.ewadconvention.org/">
              <button type="button" className="btn app-btn-primary">
                View Registration details
              </button>
            </Link>
          </div>
          <div className="col-12 col-lg-5 order-1 order-lg-2 mb-4">

          </div>

          <div className="col-12 order-3 order-lg-3 text-center mt-5 mb-5">
            <h2 className="mb-3" style={{ fontWeight: "900" }}>
              About <b style={{ color: "#8179FF" }}>Nigeria</b>
            </h2>
            <p>
              As you prepare to attend the Rosicrucian Convention in Port
              Harcourt, Nigeria tagged RCPH24, we are
              <br className="d-lg-block d-none" /> delighted to share with you
              some of the wonderful aspects of our vibrant and welcoming
              country.
            </p>

            <h5 className="mb-5" style={{ color: "#8179FF" }}>
              Often referred to as the "Giant of Africa", Nigeria is renowned
              for its rich <br className="d-lg-block d-none" /> cultural
              heritage, warm hospitality, and dynamic economy. The energy is
              <br className="d-lg-block d-none" />
              contagious! The people of Nigeria will leave you inspired and
              energized.
            </h5>

            <Link to="/nigeria-facts">
              <button type="button" className="btn app-btn-primary-outline">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
