/** @format */

import React from "react";
import style from "../assets/style/FactsAboutNigeriaHeroSection.module.css";
import Slider from "react-slick";
import arrow from "../assets/arrow-dowm.svg";
import nigeriaImages from "./../nigeria";

function FactsAboutNigeriaHeroSection() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  };
  return (
    <div className={`${style.dignitaries_container} py-5`}>
      <div className=" w-100 pt-5">
        <div className="px-sm-5 px-2">
          <div className="row m-0 mt-5">
            <div className="col-12 text-center mb-5">
              <h4
                className="mb-3"
                style={{
                  color: "#A2ACD1",
                  fontSize: "40px",
                  fontWeight: "700",
                  lineHeight: "48px",
                }}>
                Greetings!
              </h4>
              <h1
                style={{
                  fontSize: "60px",
                  fontWeight: "700",
                  lineHeight: "81.98px",
                }}>
                Welcome to Nigeria
              </h1>
              <h6 style={{ color: "#EDEDED", lineHeight: "34.2px" }}>
                As you prepare to attend the Rosicrucian Convention in Port
                Harcourt, Nigeria tagged RCPH24, we are
                <br className="d-lg-block d-none" /> delighted to share with you
                some of the wonderful aspects of our vibrant and welcoming
                country.
              </h6>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-5 text-center">
          <img src={arrow} alt="arrow" />
        </div>
        <Slider {...settings}>
          {nigeriaImages &&
            nigeriaImages.map((img, i) => (
              <div key={i} className={`${style.image_container} px-2 py-2`}>
                <img src={img.img} alt={img.label} width="100%" />
              </div>
            ))}
        </Slider>

        <div className="px-sm-5 px-2 mt-5">
          <div className="col-12 col-lg-10">
            <h4
              className="mb-3"
              style={{
                color: "#A2ACD1",
                fontSize: "40px",
                fontWeight: "700",
                lineHeight: "48px",
              }}>
              Often referred to as the "Giant of Africa", Nigeria is renowned
              for its rich cultural heritage, warm hospitality, and dynamic
              economy. The energy is contagious! The people of Nigeria will
              leave you inspired and energized.
            </h4>
            <h6 style={{ color: "#EDEDED", lineHeight: "34.2px" }}>
              Nigeria is a fascinating country and will probably be the most
              memorable country you visit. A country boasting of Two Hundred
              Million plus people!
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FactsAboutNigeriaHeroSection;
