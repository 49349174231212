/** @format */

import React from "react";
import style from "../assets/style/Speaker.module.css";

function Speaker() {
  return (
    <div className={`${style.speaker_container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div
          className="row m-0"
          style={{
            background: "#100F1B",
            minHeight: "60vh",
            borderRadius: "40px",
            padding: "60px 20px",
          }}>
          <div className="col-12 text-center mb-5">
            <h2>
              <b style={{ color: "#8179FF" }}>Goodwill Messages</b>
            </h2>
          </div>
          <div className="col-12 text-center">
            <div
              className="mb-2"
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                width: "100%",
                display: "flex",
              }}>
                <ul
                    className="speaker-nav nav nav-pills mb-5"
                    id="pills-tab"
                    role="tablist">
                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link active button"
                            id="pills-speaker-one-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-one"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-one"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Sven Johansson</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-two-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-two"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-two"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Live Söderlund</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-three-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-three"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-three"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Mirko Palomba</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-four-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-four"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-four"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Lucy Crawford-Sandison</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-5-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-5"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-5"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master ALEXANDER CROCOLLl</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-6-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-6"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-6"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Raul Passos </h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-7-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-7"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-7"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Grand Master Julie Scott</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    <li className="nav-item mx-2" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-speaker-8-tab"
                            data-toggle="pill"
                            data-target="#pills-speaker-8"
                            type="button"
                            role="tab"
                            aria-controls="pills-speaker-8"
                            aria-selected="true">
                            <div className="d-flex flex-row align-items-center">
                                <div className="bd-highlight px-5 flex-fill">
                                    <div className="text-centre">
                                        <h6>Regional Monitor Rose Imonieroh</h6>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>

                    {/*<li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-speaker-two-tab"
                    data-toggle="pill"
                    data-target="#pills-speaker-two"
                    type="button"
                    role="tab"
                    aria-controls="pills-speaker-two"
                    aria-selected="true">
                    <div className="d-flex flex-row bd-highlight align-items-center">
                      <div className="bd-highlight">
                        <img
                          src={two}
                          alt="imgAva"
                          width="80px"
                          height="80px"
                          className="rounded-circle"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="bd-highlight px-2 flex-fill">
                        <div className="text-left">
                          <h6>David E Wategire</h6>
                          <p className="m-0">Grand councillor</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-speaker-three-tab"
                    data-toggle="pill"
                    data-target="#pills-speaker-three"
                    type="button"
                    role="tab"
                    aria-controls="pills-speaker-three"
                    aria-selected="true">
                    <div className="d-flex flex-row bd-highlight align-items-center">
                      <div className="bd-highlight">
                        <img
                          src={three}
                          alt="imgAva"
                          width="80px"
                          height="80px"
                          className="rounded-circle"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="bd-highlight px-2 flex-fill">
                        <div className="text-left">
                          <h6>GC Edwin Obani</h6>
                          <p className="m-0">Host Grand Councillor</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </li>*/}
                </ul>
            </div>
              <div className="tab-content" id="pills-tabContent">
                  <div
                      className="tab-pane fade show active"
                      id="pills-speaker-one"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-one-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "50%"}}>
                              <div className="card-body">
                                  <iframe
                                      className=""
                                      style={{objectFit: "contain"}}
                                      width="100%" height="315"
                                      src="https://www.youtube.com/embed/9RoP6lm_4mg?si=TeN_id6fcOouxyr2"
                                      title="YouTube video player"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-two"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-two-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "50%"}}>
                              <div className="card-body">
                                  <iframe
                                          className=""
                                          style={{objectFit: "contain"}}
                                          width="100%" height="315"
                                          src="https://www.youtube.com/embed/M5KH04gEV9o?si=3Ot7FBTkjJum6P59"
                                          title="Grand Master Live Söderlund"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-three"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-three-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "50%"}}>
                              <div className="card-body">

                                  <iframe
                                      width="100%" height="315"
                                      src="https://www.youtube.com/embed/-fL9QyF1rf4?si=mqgvcqhqTXKBHI8Q"
                                      title="Grand Master Mirko Palomba"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-four"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-four-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "50%"}}>
                              <div className="card-body">
                                  <iframe width="100%" height="315"
                                          src="https://www.youtube.com/embed/TC7Dp6--PCE?si=KQco4qMV0B9QU3vu"
                                          title="Grand Master Lucy Crawford-Sandison" frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-5"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-5-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "30%"}}>
                              <div className="card-body">
                                  <video width="100%" height="315" controls>
                                      <source src="https://storage.googleapis.com/bulletin-ewad/Crocoll.MOV"
                                              type="video/mp4"/>
                                      Your browser does not support the video tag.
                                  </video>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-6"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-6-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "30%"}}>
                              <div className="card-body">
                                  <video width="100%" height="315" controls>
                                      <source src="https://storage.googleapis.com/bulletin-ewad/Raul%20Passos%201.MOV"
                                              type="video/mp4"/>
                                      Your browser does not support the video tag.
                                  </video>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-7"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-7-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "30%"}}>
                              <div className="card-body">
                                  <video width="100%" height="315" controls>
                                      <source src="https://storage.googleapis.com/bulletin-ewad/Julie%20Scott.MOV"
                                              type="video/mp4"/>
                                      Your browser does not support the video tag.
                                  </video>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      className="tab-pane fade"
                      id="pills-speaker-8"
                      role="tabpanel"
                      aria-labelledby="pills-speaker-8-tab">
                      <div className="px-md-5">
                          <div className="card mx-auto" style={{width: "50%"}}>
                              <div className="card-body">
                                  <iframe width="100%" height="315"
                                          src="https://www.youtube.com/embed/hl_asD4x0K0?si=c2rZ7r4uI2W1hYG8"
                                          title="Regional Monitor Rose Imonieroh" frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/*<div
                className="tab-pane fade"
                id="pills-speaker-two"
                role="tabpanel"
                aria-labelledby="pills-speaker-two-tab">
                <div className="px-md-5">
                  <div className="card mx-auto" style={{ width: "30%" }}>
                    <div className="card-body">
                      <div className="embed-responsive">
                        <img
                          src={two}
                          alt="imgAva"
                          width="100%"
                          className=""
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-speaker-three"
                role="tabpanel"
                aria-labelledby="pills-speaker-three-tab">
                <div className="px-md-5">
                  <div className="card mx-auto" style={{ width: "30%" }}>
                    <div className="card-body">
                      <div className="embed-responsive">
                        <img
                          src={three}
                          alt="imgAva"
                          width="100%"
                          className=""
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Speaker;
