/** @format */

import React from "react";
import style from "../assets/style/Event.module.css";

function Event() {
  return (
    <div className={`${style.event_container} py-5`}>
      <div className="px-lg-5 w-100">
        <div className="row m-0">
          <div className="col-12 text-center mb-5">
            <h2>Event Schedule</h2>
          </div>
          <div className="col-12 text-center">
            <div
              className="mb-3"
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                width: "100%",
                display: "flex",
              }}>
              <ul
                className="event nav nav-pills mb-5 mx-auto"
                id="pills-tab"
                role="tablist">
                <li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-event-one-tab"
                    data-toggle="pill"
                    data-target="#pills-event-one"
                    type="button"
                    role="tab"
                    aria-controls="pills-event-one"
                    aria-selected="true">
                    <div className="px-2 py-2">
                      <p className="m-0 text-nowrap">31st October, 2024</p>
                      <span className="m-0 text-nowrap">Thursday</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-event-two-tab"
                    data-toggle="pill"
                    data-target="#pills-event-two"
                    type="button"
                    role="tab"
                    aria-controls="pills-event-two"
                    aria-selected="false">
                    <div className="px-2 py-2">
                      <p className="m-0 text-nowrap"> 1st November, 2024</p>
                      <span className="m-0 text-nowrap">Friday</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-event-three-tab"
                    data-toggle="pill"
                    data-target="#pills-event-three"
                    type="button"
                    role="tab"
                    aria-controls="pills-event-three"
                    aria-selected="false">
                    <div className="px-2 py-2">
                      <p className="m-0 text-nowrap">2nd November, 2024</p>
                      <span className="m-0 text-nowrap">Saturday</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item mx-2" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-event-four-tab"
                    data-toggle="pill"
                    data-target="#pills-event-four"
                    type="button"
                    role="tab"
                    aria-controls="pills-event-four"
                    aria-selected="false">
                    <div className="px-2 py-2">
                      <p className="m-0 text-nowrap">3rd November, 2024</p>
                      <span className="m-0 text-nowrap">Sunday</span>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-event-one"
                role="tabpanel"
                aria-labelledby="pills-event-one-tab">
                <div className="px-lg-5">
                  <div className="row m-0">
                    <div className={`${style.col}`}>
                      <div className={`${style.timeline}`}>
                        <div
                          className={`${style.timeline__content} ${style.right}`}>
                          <div className={`${style.content}`}>
                            <div className="exp">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                All attendees
                              </button>
                                <div className="list-group mt-2">
                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            WELCOME AND REGISTRATION
                                        </p>
                                    </div>
                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center"></div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            OPENING ADDRESS: EMBRACING UNIVERSAL LOVE
                                        </p>
                                    </div>

                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            UNIVERSAL LOVE KEYNOTE ADDRESS
                                        </p>
                                    </div>
                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            TRADITIONAL DRUM AND DANCE PERFORMANCE:
                                            RHYTHMS OF LOVE
                                        </p>
                                    </div>
                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            LIGHTING OF THE UNITY CANDLE
                                        </p>
                                    </div>
                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            CULTURAL EXCHANGE AND NETWORKING: LOVE
                                            ACROSS BORDERS
                                        </p>
                                    </div>

                                    <div className="list-group-item list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="mb-1 row align-items-center">
                                      <span
                                          style={{color: "#949494"}}
                                          className="ml-1"></span>
                                            </div>
                                        </div>
                                        <p
                                            style={{color: "#FFFFFF"}}
                                            className="mb-1">
                                            TRADITIONAL MARTINIST ORDER ACTIVITIES AND CONVOCATION (MEMBERS ONLY)
                                        </p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div
                    className="tab-pane fade"
                    id="pills-event-two"
                    role="tabpanel"
                    aria-labelledby="pills-event-two-tab">
                    <div className="px-5">
                        <div className="row m-0">
                            <div className={`${style.col}`}>
                                <div className={`${style.timeline}`}>
                                    <div
                                        className={`${style.timeline__content} ${style.right}`}>
                                        <div className={`${style.content}`}>
                                            <div className="exp">
                                            <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                Members Only
                              </button>
                              <div className="list-group mt-2">
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        7:00 AM - 8:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Welcome & Collection of Convention Bags
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Pronaos Convocation
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        12 NOON - 12:30 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Harmonisation with Council of Solace
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        3:00 PM - 3:30 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Session
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        3:30 PM - 4:00 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Exercise
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        5:30 PM - 6:00 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Session - GC's Panel Discussion
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        6:30 PM - 7:30 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Liber Lucis Drama: Book of Light
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-event-three"
                role="tabpanel"
                aria-labelledby="pills-event-three-tab">
                <div className="px-5">
                  <div className="row m-0">
                    <div className={`${style.col}`}>
                      <div className={`${style.timeline}`}>
                        <div
                          className={`${style.timeline__content} ${style.right}`}>
                          <div className={`${style.content}`}>
                            <div className="exp">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                Members Only
                              </button>
                              <div className="list-group mt-2">
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        7:00 AM - 8:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Welcome & Collection of Folders
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 9:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Session with Exercise
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:30 AM - 10:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Session: Research Team Roundtable
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        10:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical Fraternity Activity
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        12:00 NOON - 12:30 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Harmonisation with Council of Solace
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        3:30 AM - 4:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    AMORC Welfare Fund Launch
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        6:00 PM - 8:00 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Love Banquet
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className={`${style.timeline__content} ${style.left}`}>
                          <div className={`${style.content}`}>
                            <div className="exp">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                Junior Order and Colombes
                              </button>
                              <div className="list-group mt-2">
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Registration and Welcome
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Opening Ritual and Invocation
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Keynote Address by International Rosicrucian
                                    Dignitary
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Lunch Break
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Panel Discussion on Global Trends in
                                    Rosicrucianism
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Networking Session with International
                                    Dignitaries
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Opening Banquet and Social Gathering
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-event-four"
                role="tabpanel"
                aria-labelledby="pills-event-four-tab">
                <div className="px-5">
                  <div className="row m-0">
                    <div className={`${style.col}`}>
                      <div className={`${style.timeline}`}>
                        <div
                          className={`${style.timeline__content} ${style.right}`}>
                          <div className={`${style.content}`}>
                            <div className="exp">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                Members Only
                              </button>
                              <div className="list-group mt-2">
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Mystical (Lodge) Convocation
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        12:00 NOON - 1:00 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Closing Ceremony / Presentation of Gifts
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        1:30 PM - 2:00 PM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Group Photographs
                                  </p>
                                </div>
                                {/*<div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Lunch Break
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Panel Discussion on Global Trends in
                                    Rosicrucianism
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Networking Session with International
                                    Dignitaries
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Opening Banquet and Social Gathering
                                  </p>
                                </div>*/}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*<div
                          className={`${style.timeline__content} ${style.left}`}>
                          <div className={`${style.content}`}>
                            <div className="exp">
                              <button
                                type="button"
                                className="btn"
                                style={{
                                  background: "#D9D9D9",
                                  color: "#3E38A5",
                                  width: "200px",
                                  minHeight: "50px",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  fontSize: "20px",
                                  textAlign: "left",
                                }}>
                                Junior Order and Colombes
                              </button>
                              <div className="list-group mt-2">
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Registration and Welcome
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Opening Ritual and Invocation
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Keynote Address by International Rosicrucian
                                    Dignitary
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Lunch Break
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Panel Discussion on Global Trends in
                                    Rosicrucianism
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Networking Session with International
                                    Dignitaries
                                  </p>
                                </div>
                                <div className="list-group-item list-group-item-action">
                                  <div className="d-flex w-100 justify-content-between">
                                    <div className="mb-1 row align-items-center">
                                      <div
                                        style={{
                                          width: "12px",
                                          height: " 12px",
                                          border: "1px solid #8179FF",
                                          background: "#D8D2D2",
                                          borderRadius: "50%",
                                        }}></div>
                                      <span
                                        style={{ color: "#949494" }}
                                        className="ml-1">
                                        9:00 AM - 10:30 AM
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    style={{ color: "#FFFFFF" }}
                                    className="mb-1">
                                    Opening Banquet and Social Gathering
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
