/** @format */
import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../sections/Layout";
import NigerianFactsLayout from "../sections/NigerianFactsLayout";
import Footer from "../sections/Footer";
import Header from "../sections/Header";

const WebsiteRoute = () => {
  const [colorChange, setColorChange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <div className={colorChange ? "colorChange" : "header"}>
        <Header></Header>
      </div>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/nigeria-facts" element={<NigerianFactsLayout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
};

export default WebsiteRoute;
