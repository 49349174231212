/** @format */

import React from "react";
import style from "../assets/style/Hero.module.css";

function Hero() {
  return (
    <div className={style.main_container}>
      <div
        className={`${style.main_container_row} row m-0 align-items-center`}
        style={{ height: "100vh" }}>
        <div className="col-12">
          <div className="text-center">
            <h3 className="d-none d-sm-block">ROSICRUCIAN CONVENTION</h3>
            <h5 className="d-block d-sm-none">ROSICRUCIAN CONVENTION</h5>
            <h2 className="d-none d-sm-block">Theme:</h2>
            <h4 className="d-block d-sm-none">Theme:</h4>
            <h1 className="d-none d-sm-block">UNIVERSAL LOVE</h1>
            <h3 className="d-block d-sm-none">UNIVERSAL LOVE</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
