/** @format */
 const dignitariesImages = [
  {
    img: require("./assets/dignitaries/Fr. Claudio mazzucco - Imperator & President Supreme Grand Lodge AMORC.jpg"),
    label: "Image One",
  },
    {
        img: require("./assets/dignitaries/FR Kenneth Idiodi - Honorary Grand Master.jpg"),
        label: "Image 11",
    },
  {
    img: require("./assets/dignitaries/Fr. Heverton Guzzi - Grand Master for the Portuguese Jurisdiction.jpg"),
    label: "Image Two",
  },
  {
    img: require("./assets/dignitaries/Fr. José Botello Grand Master for Hispanic Jurisdiction for Latin America.jpg"),
    label: "Image Three",
  },
  {
    img: require("./assets/dignitaries/Fr. Mirko palomba - Grand Master for Italian Jurisdiction.jpg"),
    label: "Image Four",
  },
  {
    img: require("./assets/dignitaries/Fr. Sven johansson - Grand Master, Vice President Supreme Grand Lodge AMORC.jpg"),
    label: "Image Five",
  },
  {
    img: require("./assets/dignitaries/Fr. Raul passos - Grand Master for the Southeastern European Grand Lodge of Romania, Bulgaria, Moldova, Georgia & Northern Macedonia.jpg"),
    label: "Image Six",
  },
    {
        img: require("./assets/dignitaries/FR. ALEXANDER CROCOLL -  Grand Master of the German Jurisdiction.jpg"),
        label: "Image Seven",
    },
    {
        img: require("./assets/dignitaries/SR. Julie Scott - Grand Master Lodge AMORC.jpg"),
        label: "Image Eight",
    },
    {
        img: require("./assets/dignitaries/Sr. Live Söderlund Grand Master, Treasurer Supreme Grand Lodge AMORC.jpg"),
        label: "Image Nine",
    },
    {
        img: require("./assets/dignitaries/Sr Lucy Crawford-Sandison - Grand Master Anglophone Jurisdiction of Asia, Australia and New Zealand.jpg"),
        label: "Image Ten",
    },
    {
        img: require("./assets/dignitaries/Fr Eugenius Idiodi - Grand Master Elect English Grand Lodge for West Africa.jpg"),
        label: "Image 12",
    }
];

export default dignitariesImages;
