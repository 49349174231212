/** @format */

import React from "react";
import style from "../assets/style/Footer.module.css";
import envelop from "../assets/envelop.svg";
import instagram from "../assets/Instagram.svg";
import LinkedIn from "../assets/LinkedIn.svg";
import facebook from "../assets/Facebook.svg";
import youtube from "../assets/YouTube.svg";
import {Link} from "react-router-dom";

function Footer() {
  return (
    <div className={style.footer_container}>
      <div className="px-5" style={{ width: "100%" }}>
        <div className="row m-0 justify-content-center">
          <div className="col-12 col-md-4 col-lg-3 mb-3">
            <span>
              <img src={envelop} alt="envlop" className="mr-2" />
              ewadconvention@amorc.org.ng
            </span>
          </div>
          <div className="col-12 col-md-4 col-lg-3 mb-3 text-lg-center">

            <Link to="https://web.facebook.com/AMORCEGLWestAfrica">
              <img
                  src={facebook}
                  alt="social Icons"
                  width="30px"
                  className="mx-1"
              />
            </Link>

            <Link to="https://www.instagram.com/amorceglwa/">
              <img
                  src={instagram}
                  alt="social Icons"
                  width="30px"
                  className="mx-1"
              />
            </Link>

            <Link to="https://www.youtube.com/@AMORCEGLWA" >
              <img
                  src={youtube}
                  alt="social Icons"
                  width="30px"
                  className="mx-1"
              />
            </Link>
            <Link to="https://www.linkedin.com/company/amorcng/">
              <img
                  src={LinkedIn}
                  alt="social Icons"
                  width="30px"
                  className="mx-1"
              />
            </Link>

          </div>
          <div className="col-12 col-md-4 col-lg-6 mb-3 text-lg-right">
            <span>
              2024 EGLWA AMORC. All rights reserved | <Link to="https://register.ewadconvention.org/terms-and-policy">
              Terms & Payment policy
            </Link>  |
              About AMORC
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
