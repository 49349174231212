/** @format */

import React from "react";
import style from "../assets/style/Feature.module.css";

function Feature() {
  return (
    <div className={`${style.feature_container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-5" style={{ fontWeight: "900" }}>
              Featured Events
            </h2>
            <h3
              className="mb-5"
              style={{ fontWeight: "400", fontSize: "24px" }}>
              Delve into unique practices with tailored sessions for Rosicrucians and Martinists, <br className="d-none d-lg-block" /> exclusive mystical convocations and so much more, fostering{" "}
              <br className="d-none d-lg-block" /> spiritual growth and
              community connection.
            </h3>
          </div>
          <div className="col-12">
            <div className="row m-0">
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.firstImage}`}>
                  <p>
                    Mystical Convocation, Rosicrucian <br /> Exercises and
                    Martinist Session
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.secondImage}`}>
                  <p>Esoteric Presentations</p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.thirdImage}`}>
                  <p>Workshop and Interactive Sessions</p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.fourthImage}`}>
                  <p>Panel Discussions</p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.fifthImage}`}>
                  <p>Cultural Dances</p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.sixthImage}`}>
                  <p>Banquet</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
