/** @format */

import React from "react";
import style from "../assets/style/Gallery.module.css";
import venueImages from "./../venueImage";



function Gallery() {
  return (
    <div className={`${style.Gallery_container} py-5`}>
      <div className="px-2 px-sm-5 w-100">
        <div className="row m-0">
          <div className="col-12 text-center mb-5">
            <h4>Gallery</h4>
            <h2>Location Spotlight: Where the Event Happens!</h2>
          </div>
          <div className="col-12 text-center">
            <div className={`${style.Gallery_row} m-0 mb-5`}>
              {venueImages &&
                  venueImages.map((img, i) => (
                  <div className={style.Gallery_column}>
                    <img key={i} src={img.img} alt={img.label} width="100%" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
