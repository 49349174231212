/** @format */

import React from "react";
import style from "../assets/style/Enlignment.module.css";
import expression from "../assets/expression.svg";
import hand from "../assets/handshake.svg";
import group from "../assets/group.svg";
import banner from "../assets/banner.png";
import {Link} from "react-router-dom";

function Enlignment() {
  return (
    <div className={`${style.enlignment_Container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div className="row m-0 justify-content-center">
          <div className="col-12 text-center mb-5 ">
            <h2>
              The Convention is Designed to Enhance your Mystical Growth
            </h2>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <img
              src={expression}
              alt="expression"
              width="70px"
              className="mb-3"
            />
            <h4 className="mb-3">Spiritual Enrichment</h4>
            <p className="mb-3">
              Attendees can enrich their understanding of the Rosicrucian Tradition through diverse activities, promoting both personal and collective spiritual growth.
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <img src={hand} alt="hand" width="70px" className="mb-3" />
            <h4 className="mb-3">Expression of Ideals</h4>
            <p className="mb-3">
              Through shared experiences and expressions, attendees will have the opportunity to embody the principles and values of the Rosicrucian Tradition in their daily lives.
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <img src={group} alt="group" width="70px" className="mb-3" />
            <h4 className="mb-3">Community Building</h4>
            <p className="mb-3">
              The convention acts as a unifying space, bringing together members to cultivate a sense of community among those who share common ideals.
            </p>
          </div>
          <div className="col-12 mt-5 text-center">
            <img src={banner} alt="banner" width="100%" />
            <div className="mt-5">
              <Link to="https://register.ewadconvention.org/" className="nav-link">
                <button type="button" className="btn app-btn-primary">
                  View Registration details
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enlignment;
