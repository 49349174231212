/** @format */

import React from "react";
import FactsAboutNigeriaHeroSection from "./FactsAboutNigeriaHeroSection";
import VisaRequirement from "./VisaRequirement";
import BeautifyThings from "./BeautifyThings";
import NigeriaBanner from "./NigeriaBanner";

function NigerianFactsLayout() {
  return (
    <div>
      <FactsAboutNigeriaHeroSection />
      <VisaRequirement />
      <BeautifyThings />
      <NigeriaBanner />
    </div>
  );
}

export default NigerianFactsLayout;
