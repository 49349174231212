/** @format */

import React from "react";
import style from "../assets/style/VisaRequirement.module.css";
import replacement from "../assets/visa.svg";
import visa_badge from "../assets/nig_6.svg";
import thick from "../assets/thick.svg";

function VisaRequirement() {
  return (
    <div className={`${style.visaRequirement_container} py-5`}>
      <div className="px-sm-5 px-2 w-100 text-center">
        <div className="mb-3">
          <h3>Visa Requirements</h3>
          <p>
            Nigeria offers various visa options to accommodate different types
            of visitors. Here is an overview
            <br className="d-lg-block d-none" /> of the visa requirements for
            tourists, business travellers, and those eligible for Visa on
            Arrival.
          </p>
        </div>
        <ul
          style={{
            background: "#F5F5F526",
            borderRadius: "48px",
          }}
          className={`${style.nav_pills} visa nav nav-pills mt-5 mb-5 nav-fill`}
          id="pills-tab"
          role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`${style.nav_link} nav-link active`}
              id="pills-tourist-visa-tab"
              data-toggle="pill"
              data-target="#pills-tourist-visa"
              type="button"
              role="tab"
              aria-controls="pills-tourist-visa"
              aria-selected="true">
              Tourist Visa
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-business-visa-tab"
              data-toggle="pill"
              data-target="#pills-business-visa"
              type="button"
              role="tab"
              aria-controls="pills-business-visa"
              aria-selected="false">
              Business Visa
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-visa-arrival-tab"
              data-toggle="pill"
              data-target="#pills-visa-arrival"
              type="button"
              role="tab"
              aria-controls="pills-visa-arrival"
              aria-selected="false">
              Visa on Arrival (VoA)
            </button>
          </li>
        </ul>
        <div className="tab-content mb-5" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-tourist-visa"
            role="tabpanel"
            aria-labelledby="pills-tourist-visa-tab">
            <div className="row m-0 text-left">
              <div className="col-12 col-lg-6 mb-3">
                <h5 className="mb-4">Tourist Visa</h5>
                <p className="mb-1" style={{ fontWeight: "400" }}>
                  Tourist visas are issued to travellers visiting Nigeria for
                  tourism, vacation, or visiting friends and family. To apply
                  for a tourist visa, you will need
                </p>
                <div className="mt-3">
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A valid passport with at least six months of validity
                        and two blank visa pages.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A completed visa application form.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Two recent passport-sized photographs.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Proof of accommodation (hotel reservation or invitation
                        letter from a host in Nigeria).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <div
                  style={{
                    background: "#2B2939",
                    width: "100%",
                    padding: "0px 61.05px 0px 0.04px",
                    borderRadius: "25px",
                    margin: "auto",
                  }}>
                  <img src={replacement} alt="replacement" width="100%" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-business-visa"
            role="tabpanel"
            aria-labelledby="pills-business-visa-tab">
            <div className="row m-0 text-left">
              <div className="col-12 col-lg-6">
                <h5 className="mb-4">Business Visa</h5>
                <p className="mb-1" style={{ fontWeight: "400" }}>
                  Business visas are issued to travellers visiting Nigeria for
                  tourism, vacation, or visiting friends and family. To apply
                  for a Business visa, you will need
                </p>
                <div className="mt-3">
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A valid passport with at least six months of validity
                        and two blank visa pages.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A completed visa application form.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Two recent passport-sized photographs.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Proof of accommodation (hotel reservation or invitation
                        letter from a host in Nigeria).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <div
                  style={{
                    background: "#2B2939",
                    width: "100%",
                    padding: "0px 61.05px 0px 0.04px",
                    borderRadius: "25px",
                    margin: "auto",
                  }}>
                  <img src={replacement} alt="replacement" width="100%" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-visa-arrival"
            role="tabpanel"
            aria-labelledby="pills-visa-arrival-tab">
            <div className="row m-0 text-left">
              <div className="col-12 col-lg-6">
                <h5 className="mb-4">Visa Arrival</h5>
                <p className="mb-1" style={{ fontWeight: "400" }}>
                  Visa Arrival visas are issued to travellers visiting Nigeria
                  for tourism, vacation, or visiting friends and family. To
                  apply for a Visa Arrival, you will need
                </p>
                <div className="mt-3">
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A valid passport with at least six months of validity
                        and two blank visa pages.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        A completed visa application form.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Two recent passport-sized photographs.
                      </p>
                    </div>
                  </div>
                  <div className="media position-relative align-items-center">
                    <img
                      src={thick}
                      className="mr-3"
                      alt="thick"
                      width="32px"
                    />
                    <div className="media-body">
                      <p className="mb-1" style={{ fontWeight: "400" }}>
                        Proof of accommodation (hotel reservation or invitation
                        letter from a host in Nigeria).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 text-center">
                <div className="row m-0 align-items-center">
                  <div
                    className="col-12 px-0 py-0"
                    style={{
                      background: "#2B2939",
                      width: "100%",
                      borderRadius: "25px",
                      margin: "auto",
                    }}>
                    <img src={replacement} alt="replacement" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ background: "#1C1A31" }}
          className="mt-5 py-4 px-4 rounded">
          <div className="row m-0 text-left align-items-center">
            <div className="col-12 col-lg-4 text-center">
              <img src={visa_badge} alt="visa_badge" width="340px" />
            </div>
            <div className="col-12 col-lg-8 pl-5">
              <h5 className="mb-4">Security</h5>
              <p className="mb-1" style={{ fontWeight: "400" }}>
                Nigeria is committed to ensuring the safety and security of its
                residents and visitors. Major cities like Lagos, Abuja, and Port
                Harcourt, where most conferences and events take place, have
                robust security measures in place. The Nigerian government works
                tirelessly to maintain peace and order, with well-coordinated
                efforts from the police and security agencies. As with any
                international travel, it is always a good idea to stay informed
                and follow local guidelines.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisaRequirement;
