/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import { FaBars } from "react-icons/fa6";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-transparent">
      <Link to="" className="navbar-brand">
        <Logo />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <FaBars className="text-white" />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item mr-3">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item mr-3">
            <Link to="/nigeria-facts" className="nav-link">
              Nigerian Facts
            </Link>
          </li>
        </ul>
        <div className="form-inline my-2 my-lg-0 mr-3">
          <Link to="https://register.ewadconvention.org/" className="nav-link">
            <button className="btn app-btn-primary" type="button">
              Register
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
