/** @format */

import React from "react";
import logo from "../assets/amorc-logo-cropped.svg";

const Logo = () => {
  return <img src={logo} alt="amorc-logo" style={{ width: "180px" }} />;
};

export default Logo;
